import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Container from '../../components/Container/Container'
import Hero from '../../components/Hero/Hero'
import Layout from '../../components/Layout/Layout'
import SEO from '../../components/SEO/SEO'
import Section from '../../components/Section/Section'

import './index.css'

const MembershipPage = ({
  data: {
    communityImageData: { childImageSharp: communityImageSharp },
    developmentImageData: { childImageSharp: developmentImageSharp },
  }
}) => (
    <Layout linkMembershipToMobilize>
      <SEO title="Membership" />
      <Hero title="Membership">
        <p>A Community for Black People in Technology</p>
      </Hero>
      <Section className="Community MembershipPage--Section">
        <Container>
          <div className="MembershipPage--Section--rows">
            <div className="MembershipPage--Section--content">
              <h3>How we’re fostering community through Melanin.Tech</h3>
              <p>You’ll be part of a community full of tech professionals and those who are beginning their journey into the industry. It’s a perfect place to find mentors*, collaborators, new partnerships, and build teams!</p>
              <p className="font-small" style={{ color: '#98999C' }}>*All mentors are welcome to join, regardless of racial identity, with limited access to the full community. </p>
            </div>
            <div className="MembershipPage--Section--image">
              <Img {...communityImageSharp} alt="Two members collaborating in a meeting" />
            </div>
          </div>
        </Container>
      </Section>
      <Section className="Development MembershipPage--Section">
        <Container>
          <div className="MembershipPage--Section--rows">
            <div className="MembershipPage--Section--content">
              <h3>How we’re encouraging development in our community</h3>
              <p>Members have an all-access pass to webinars, workshops, live stream conversations, community discussions, and connection to the full library of previously recorded events. You’ll be able to subscribe to the event calendar to ensure you never miss a beat!</p>
              <p>We’ll share screened opportunities such as jobs, internships, and fellowships. We only work with companies and organizations who we believe will support you while encouraging growth.</p>
            </div>
            <div className="MembershipPage--Section--image">
              <Img {...developmentImageSharp} alt="Three members chatting over a coffee" />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )

export const pageQuery = graphql`
  query MembershipPageQuery {
    communityImageData: file(relativePath: { eq: "MembershipPage/MembershipPage--community.png" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    developmentImageData: file(relativePath: { eq: "MembershipPage/MembershipPage--development.png" }) {
      childImageSharp {
        fluid(maxWidth: 590, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default MembershipPage
